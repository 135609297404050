<template>
	<div class="wrap__content">
		<div class="container">
			<div class="row">
				<div class="col-lg-4 offset-lg-4">
					<div class="login__lang header__lang">
						<HeaderLang></HeaderLang>
					</div>
					<div class="header__logo login__logo">
						<div class="header__logo--left">
							<a :href="'/' + $i18n.locale + '/'">
								<img src="../assets/img/logo.svg" alt="" />
							</a>
						</div>
						<div class="header__logo--right">
							<div class="header__logo--title">
								{{ $t('site.logo.title') }}
							</div>
							<div class="header__logo--subtitle">
								{{ $t('site.logo.desc') }}
							</div>
						</div>
					</div>
					<div class="login__block registration__block">
						<div class="content__title--block">
							<div class="content__title section__title">{{ $t('site.registration.title') }}</div>
						</div>
						<div class="login__block--form">
							<div class="row">
								<div class="col-lg-12">
									<div class="login__block--tab login__block--active">
										<form method="POST" enctype="multipart/form-data" @submit.prevent="onSubmit">
											<div class="form__block--line" v-if="!result">
												<label
													class="form__block--input"
													:class="[
														errorMessage.messages && errorMessage.messages.ecp
															? 'input--error'
															: '',
													]"
												>
													<div class="form__file" @click="refisterPki">
														<img src="../assets/img/icon-ecp.svg" />
														{{ $t('site.registration.pki') }}
													</div>
												</label>
												<div
													class="input-required"
													v-if="errorMessage.messages && errorMessage.messages.ecp"
												>
													<p>{{ errorMessage.messages.ecp }}</p>
												</div>
											</div>

											<div v-if="result">
												<div class="form__line form__line--column">
													<div class="form__line--left">
														<div class="form__line--title">
															{{ $t('site.registration.last_name') }}
														</div>
													</div>
													<div class="form__line--right">
														<div class="row">
															<div class="col-xl-12">
																<div
																	class="form__line--input form__block--input"
																	:class="[
																		errorMessage.messages && errorMessage.messages.last_name
																			? 'input--error'
																			: '',
																	]"
																>
																	<input
																		type="text"
																		class="input-linck"
																		required=""
																		name="last_name"
																		v-model="result.data.commonName.split(' ')[0]"
																		:placeholder="$t('site.registration.last_name')"
																	/>
																</div>
																<div
																	class="input-required"
																	v-if="errorMessage.messages && errorMessage.messages.last_name"
																>
																	<p
																		v-for="(massage, massageInd) in errorMessage.messages.last_name"
																		:key="massageInd"
																	>
																		{{ massage }}
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="form__line form__line--column">
													<div class="form__line--left">
														<div class="form__line--title">
															{{ $t('site.registration.name') }}
														</div>
													</div>
													<div class="form__line--right">
														<div class="row">
															<div class="col-xl-12">
																<div
																	class="form__line--input form__block--input"
																	:class="[
																		errorMessage.messages && errorMessage.messages.first_name
																			? 'input--error'
																			: '',
																	]"
																>
																	<input
																		type="text"
																		class="input-linck"
																		required=""
																		name="first_name"
																		v-model="result.data.commonName.split(' ')[1]"
																		:placeholder="$t('site.registration.name')"
																	/>
																</div>
																<div
																	class="input-required"
																	v-if="errorMessage.messages && errorMessage.messages.first_name"
																>
																	<p
																		v-for="(massage, massageInd) in errorMessage.messages
																			.first_name"
																		:key="massageInd"
																	>
																		{{ massage }}
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="form__line form__line--column">
													<div class="form__line--left">
														<div class="form__line--title">
															{{ $t('site.registration.middle_name') }}
														</div>
													</div>
													<div class="form__line--right">
														<div class="row">
															<div class="col-xl-12">
																<div
																	class="form__line--input form__block--input"
																	:class="[
																		errorMessage.messages && errorMessage.messages.middle_name
																			? 'input--error'
																			: '',
																	]"
																>
																	<input
																		type="text"
																		class="input-linck"
																		name="middle_name"
																		v-model="result.data.lastName"
																		:placeholder="$t('site.registration.middle_name')"
																	/>
																</div>
																<div
																	class="input-required"
																	v-if="errorMessage.messages && errorMessage.messages.middle_name"
																>
																	<p
																		v-for="(massage, massageInd) in errorMessage.messages
																			.middle_name"
																		:key="massageInd"
																	>
																		{{ massage }}
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div class="form__line form__line--column">
													<div class="form__line--left">
														<div class="form__line--title">
															{{ $t('site.registration.name_company') }}
														</div>
													</div>
													<div class="form__line--right">
														<div class="row">
															<div class="col-xl-12">
																<div
																	class="form__line--input form__block--input"
																	:class="[
																		errorMessage.messages && errorMessage.messages.name_company
																			? 'input--error'
																			: '',
																	]"
																>
																	<input
																		type="text"
																		class="input-linck"
																		name="name_company"
																		v-model="result.data.organization"
																		:placeholder="$t('site.registration.name_company')"
																	/>
																</div>
																<div
																	class="input-required"
																	v-if="errorMessage.messages && errorMessage.messages.name_company"
																>
																	<p
																		v-for="(massage, massageInd) in errorMessage.messages
																			.name_company"
																		:key="massageInd"
																	>
																		{{ massage }}
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div v-if="result.data.bin" class="form__line form__line--column">
													<div class="form__line--left">
														<div class="form__line--title">
															{{ $t('site.registration.iin_bin') }}
														</div>
													</div>
													<div class="form__line--right">
														<div class="row">
															<div class="col-xl-12">
																<div
																	class="form__line--input form__block--input"
																	:class="[
																		errorMessage.messages && errorMessage.messages.iin_bin
																			? 'input--error'
																			: '',
																	]"
																>
																	<input
																		type="text"
																		class="input-linck"
																		name="iin_bin"
																		disabled
																		v-model="result.data.bin"
																		:placeholder="$t('site.registration.iin_bin')"
																	/>
																</div>
																<div
																	class="input-required"
																	v-if="errorMessage.messages && errorMessage.messages.iin_bin"
																>
																	<p
																		v-for="(massage, massageInd) in errorMessage.messages.iin_bin"
																		:key="massageInd"
																	>
																		{{ massage }}
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div
													v-if="!result.data.bin && result.data.iin"
													class="form__line form__line--column"
												>
													<div class="form__line--left">
														<div class="form__line--title">
															{{ $t('site.registration.iin_bin') }}
														</div>
													</div>
													<div class="form__line--right">
														<div class="row">
															<div class="col-xl-12">
																<div
																	class="form__line--input form__block--input"
																	:class="[
																		errorMessage.messages && errorMessage.messages.iin_bin
																			? 'input--error'
																			: '',
																	]"
																>
																	<input
																		type="text"
																		class="input-linck"
																		name="iin_bin"
																		disabled
																		v-model="result.data.iin"
																		:placeholder="$t('site.registration.iin_bin')"
																	/>
																</div>
																<div
																	class="input-required"
																	v-if="errorMessage.messages && errorMessage.messages.iin_bin"
																>
																	<p
																		v-for="(massage, massageInd) in errorMessage.messages.iin_bin"
																		:key="massageInd"
																	>
																		{{ massage }}
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div class="form__line form__line--column">
													<div class="form__line--left">
														<div class="form__line--title">
															{{ $t('components.region') }}
														</div>
													</div>
													<div class="form__line--right">
														<div class="row">
															<div class="col-xl-12">
																<div
																	class="form__line--input form__block--input"
																	:class="[
																		errorMessage.messages && errorMessage.messages.region_id
																			? 'input--error'
																			: '',
																	]"
																>
																	<region
																		:class-list="['input-linck']"
																		:name="'region_id'"
																		:required="true"
																		:placeholder="$t('components.region')"
																	/>
																</div>
																<div
																	class="input-required"
																	v-if="errorMessage.messages && errorMessage.messages.region_id"
																>
																	<p
																		v-for="(massage, massageInd) in errorMessage.messages.region_id"
																		:key="massageInd"
																	>
																		{{ massage }}
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

											<template v-if="result">
												<div class="form__line form__line--column">
													<div class="form__line--left">
														<div class="form__line--title">
															{{ $t('site.registration.email') }}
														</div>
													</div>
													<div class="form__line--right">
														<div class="row">
															<div class="col-xl-12">
																<div
																	class="form__line--input form__block--input"
																	:class="[
																		errorMessage.messages && errorMessage.messages.email
																			? 'input--error'
																			: '',
																	]"
																>
																	<input
																		class="input-linck"
																		type="text"
																		name="email"
																		required=""
																		v-model="result.data.email"
																		:placeholder="$t('site.registration.email')"
																		v-if="result && result.data"
																	/>
																	<input
																		class="input-linck"
																		type="text"
																		name="email"
																		:placeholder="$t('site.registration.email')"
																		v-else
																	/>
																</div>
																<div
																	class="input-required"
																	v-if="errorMessage.messages && errorMessage.messages.email"
																>
																	<p
																		v-for="(massage, massageInd) in errorMessage.messages.email"
																		:key="massageInd"
																	>
																		{{ massage }}
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div class="form__line form__line--column">
													<div class="form__line--left">
														<div class="form__line--title">
															{{ $t('site.registration.contract') }}
														</div>
													</div>
													<div class="form__line--right">
														<div class="row">
															<div class="col-xl-12">
																<div
																	class="form__line--input form__block--input"
																	:class="[
																		errorMessage.messages && errorMessage.messages.document
																			? 'input--error'
																			: '',
																	]"
																>
																	<label class="form__line--file" :class="fileDocuments.fileClass">
																		<input
																			type="file"
																			ref="fileDocuments"
																			accept=".pdf,.png,.jpg,.doc,.docx,.excel"
																			v-on:change="handleFileUpload()"
																		/>
																		<div class="form__file">
																			<div class="form__file--icon">
																				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M20 16.2A4.5 4.5 0 0017.5 8h-1.8A7 7 0 104 14.9M12 12v9M8 17l4 4 4-4" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
																			</div>
																			<div class="form__file--text" v-if="fileDocuments.fileName">
																				{{ fileDocuments.fileName }}
																			</div>
																			<div class="form__file--text" v-else>
																				{{ $t('site.upload_file') }}
																			</div>
																		</div>
																	</label>
																</div>
																<div
																	class="input-required"
																	v-if="errorMessage.messages && errorMessage.messages.document"
																>
																	<p
																		v-for="(massage, massageInd) in errorMessage.messages.document"
																		:key="massageInd"
																	>
																		{{ massage }}
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div class="form__line form__line--column">
													<div class="form__line--left">
														<div class="form__line--title">
															{{ $t('site.registration.password') }}
															<span
																v-tooltip.top-center="{
																	content: $t('messages.password_msg'),
																	class: ['tooltip__btn'],
																}"
															>
																<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.047 16.58a7.5 7.5 0 100-15 7.5 7.5 0 000 15zM9.047 6.08v3M9.047 12.08h.007" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
															</span>
														</div>
													</div>
													<div class="form__line--right">
														<div class="row">
															<div class="col-xl-12">
																<div class="form__line--input">
																	<div
																		class="form__block--input"
																		:class="[
																			errorMessage.messages && errorMessage.messages.password
																				? 'input--error'
																				: '',
																		]"
																	>
																		<input
																			type="password"
																			class="input-linck"
																			v-model="password"
																			required=""
																			v-if="!inputType"
																			name="password"
																			:placeholder="$t('site.registration.password')"
																		/>
																		<input
																			type="text"
																			class="input-linck"
																			v-model="password"
																			required=""
																			v-else
																			name="password"
																			:placeholder="$t('site.registration.password')"
																		/>
																		<div class="password__eye">
																			<img
																				src="../assets/img/icon-eye-off.svg"
																				v-if="inputType"
																				@click="inputType = false"
																			/>
																			<img
																				src="../assets/img/icon-eye.svg"
																				v-else
																				@click="inputType = true"
																			/>
																		</div>
																	</div>
																	<div
																		class="input-required"
																		v-if="errorMessage.messages && errorMessage.messages.password"
																	>
																		<p
																			v-for="(massage, massageInd) in errorMessage.messages
																				.password"
																			:key="massageInd"
																		>
																			{{ massage }}
																		</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="form__line form__line--column">
													<div class="form__line--left">
														<div class="form__line--title">
															{{ $t('site.registration.password_repeat') }}
															<span
																v-tooltip.top-center="{
																	content: $t('messages.password_msg'),
																	class: ['tooltip__btn'],
																}"
															>
																<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.047 16.58a7.5 7.5 0 100-15 7.5 7.5 0 000 15zM9.047 6.08v3M9.047 12.08h.007" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
															</span>
														</div>
													</div>
													<div class="form__line--right">
														<div class="row">
															<div class="col-xl-12">
																<div class="form__line--input">
																	<div
																		class="form__block--input"
																		:class="[
																			errorMessage.messages && errorMessage.messages.password
																				? 'input--error'
																				: '',
																		]"
																	>
																		<input
																			type="password"
																			class="input-linck"
																			v-model="comparePassword"
																			required=""
																			v-if="!inputType"
																			name="compare"
																			:placeholder="$t('site.registration.password_repeat')"
																		/>
																		<input
																			type="text"
																			class="input-linck"
																			v-model="comparePassword"
																			required=""
																			v-else
																			name="compare"
																			:placeholder="$t('site.registration.password_repeat')"
																		/>
																		<div class="password__eye">
																			<img
																				src="../assets/img/icon-eye-off.svg"
																				v-if="inputType"
																				@click="inputType = false"
																			/>
																			<img
																				src="../assets/img/icon-eye.svg"
																				v-else
																				@click="inputType = true"
																			/>
																		</div>
																	</div>
																	<div
																		class="input-required"
																		v-if="errorMessage.messages && errorMessage.messages.compare"
																	>
																		<p
																			v-for="(massage, massageInd) in errorMessage.messages.compare"
																			:key="massageInd"
																		>
																			{{ massage }}
																		</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div class="form__line form__line--column">
													<div class="form__line--right">
														<div class="row">
															<div class="col-xl-12">
																<div class="registration_agreement">
																	<div class="registration_agreement__title">
																		{{ $t('register_agreement_title') }}
																	</div>
																	<p v-html="$t('register_agreement')"></p>
																	<label
																		for="registration_agreement_input"
																		class="form__line--input registration_agreement_input"
																	>
																		<input
																			v-model="registration_agreement"
																			id="registration_agreement_input"
																			type="checkbox"
																			required
																			name="registration_agreement"
																		/>
																		<span>{{ $t('accept') }}</span>
																	</label>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div
													v-if="message.text"
													class="form__block--line form__messages"
													:class="{
														'form__messages--error': message.status != 200,
														'form__messages--success': message.status == 200,
													}"
												>
													<label class="form__block--input">
														<span class="form__block--title">
															{{ message.text }}
														</span>
													</label>
												</div>

												<div class="form__listitem--line form__listitem--button">
													<div class="form__listitem--input">
														<button class="input__button" type="submit">
															{{ $t('site.registration.input_registration') }}
														</button>
													</div>
												</div>
											</template>
										</form>
									</div>
								</div>

								<div class="col-lg-12">
									<div class="form__listitem--registr">
										<router-link :to="'/' + $i18n.locale + '/login'">{{
											$t('site.authorization.input_login')
										}}</router-link>
									</div>
								</div>
							</div>
						</div>

						<modal
							class="modal__block modal__block--middle modal__block--success"
							name="modal_success"
							:width="550"
							:min-width="550"
							height="auto"
							:clickToClose="false"
						>
							<div class="modal__block--wrapper">
								<div class="modal__wrapper">
									<div class="modal__header">
										<div class="modal__title">
											{{ $t('site.registration.successful.title') }}
										</div>
									</div>
									<div class="modal__content">
										<div class="modal__form">
											<div class="modal__form--message message__center">
												{{ $t('site.registration.successful.desc') }}
											</div>
											<div class="form__listitem--registr">
												<router-link :to="'/' + $i18n.locale + '/login'">{{
													$t('site.authorization.input_login')
												}}</router-link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</modal>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { api } from '@/boot/axios'
import HeaderLang from '@/components/HeaderLang'

import region from '@/components/components/Inputs/region.vue'

export default {
	components: {
		HeaderLang,
		region,
	},
	data() {
		return {
			result: null,
			registrEcp: {},
			registration_agreement: false,

			fileDocuments: {
				fileName: '',
				fileClass: '',
			},

			ecpSign: false,
			token: null,
			errorMessage: {
				status: null,
				messages: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
				texts: null,
			},
			loading: null,

			password: '',
			comparePassword: '',
			inputType: false,
			dataEcp: '',
		}
	},
	beforeCreate() {
		if (localStorage.token) {
			delete localStorage.token
			location.reload()
		}
	},
	methods: {
		handleFileUpload() {
			this.fileDocuments.file = this.$refs.fileDocuments.files[0]
			this.fileDocuments.fileName = this.$refs.fileDocuments.files[0].name
			this.fileDocuments.fileClass = 'add_tourist--success'
		},
		onSubmit(evt) {
			this.message.status = null
			this.message.text = null
			this.message.texts = null

			const formData = new FormData(evt.target)
			formData.append('document', this.fileDocuments.file)
			if (this.result) formData.append('iin_bin', this.result.data.bin || this.result.data.iin)
			const fromEntries = Object.fromEntries(formData.entries())
			if (fromEntries.compare != fromEntries.password) {
				this.errorMessage.status = 422
				this.errorMessage.messages = {
					compare: [this.$t('system_message.comparePassword')],
					password: [this.$t('system_message.comparePassword')],
				}
			} else if (!this.registration_agreement) {
				this.errorMessage.messages = {
					registration_agreement: true,
				}
			} else {
				api
					.post('register', formData, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					})
					.then((response) => {
						if (response.status == 200) {
							this.errorMessage.status = response.status
							this.errorMessage.text = response.data.message

							this.$modal.show('modal_success')
						}
					})
					.catch((error) => {
						if (error?.response?.status == 401) {
							this.message.status = 401
							if (error.response.data.error_message)
								this.message.text = error.response.data.error_message
							if (error.response.data.error_messages)
								this.message.texts = error.response.data.error_messages
						}
						if (error?.response?.status == 422) {
							this.message.status = 422
							if (error.response.data.error_message)
								this.message.text = error.response.data.error_message
							if (error.response.data.error_messages)
								this.message.texts = error.response.data.error_messages
						}
						this.errorMessage.messages = error.response.data.error_messages
					})
			}
		},

		refisterPki() {
			if (this.$configFile.active) return this.sendEcpKey()
			this.dataEcp = null
			const websocket = new WebSocket('wss://127.0.0.1:13579/')
			var ready = null
			let ecpData = {
				path: null,
				owner: null,
				data: null,
			}
			websocket.onopen = () => {
				ready = true

				handle()
			}
			websocket.onclose = (e) => {
				if (!e.wasClean) {
					if (this.$i18n.locale == 'kz')
						alert('NCYLayer бағдарламасы іске қосылғандығына көз жеткізіңіз')
					if (this.$i18n.locale == 'ru') alert('Убедитесь, что программа NCALayer запущена')
					if (this.$i18n.locale == 'en') alert('Make sure that the NCALayer program is running')

					//setErrormodal(true);
					setTimeout(() => {
						//setErrormodal(false);
					}, 5000)
				}
			}

			function handle() {
				if (!ready) {
					alert('Убедитесь, что программа NCALayer запущена')
				} else {
					const data = {
						module: 'kz.gov.pki.knca.commonUtils',
						method: 'signXml',
						args: [
							'PKCS12',
							'AUTHENTICATION',
							'<login><sessionid>caacda70-fd36-45ed-8d94-45a88890f83a</sessionid></login>',
							'',
							'',
						],
					}
					websocket.send(JSON.stringify(data))
				}
			}
			websocket.onmessage = (e) => {
				const data1 = JSON.parse(e.data)
				ecpData.data = data1.responseObject
				this.dataEcp = data1.responseObject
				if (this.dataEcp) {
					this.sendEcpKey()
				}
			}
		},

		sendEcpKey() {
			this.message.status = null
			this.message.text = null
			this.message.texts = null
			api
				.post(
					'register/data',
					{
						data: this.dataEcp || this.$configFile.xml,
					},
					{},
				)
				.then((response) => {
					this.result = response.data
				})
				.catch((error) => {
					if (error?.response?.status == 401) {
						this.message.status = 401
						if (error.response.data.error_message)
							this.message.text = error.response.data.error_message
						if (error.response.data.error_messages)
							this.message.texts = error.response.data.error_messages
					}
					if (error?.response?.status == 422) {
						this.message.status = 422
						if (error.response.data.error_message)
							this.message.text = error.response.data.error_message

						if (error.response.data.error_messages)
							this.message.texts = error.response.data.error_messages
					}

					this.errorMessage.messages = {
						ecp: error.response.data.error_message || error.response.data.error_messages,
					}
				})
		},
	},
	head: {
		title() {
			return {
				inner: this.$t('site.registration.title'),
			}
		},
		meta: function () {
			return [{ name: 'description', content: this.$t('site.registration.description') }]
		},
	},
}
</script>

<style scoped>
header .header__site--row {
	display: none;
}

.registration_agreement {
	text-align: justify;
	font-size: 12px;
	line-height: 1.2;
}

.registration_agreement__title {
	text-align: center;
	font-weight: 600;
	margin-bottom: 10px;
}

.registration_agreement a {
	color: inherit;
	text-decoration: underline;
}

.registration_agreement_input {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	font-size: 14px;
	cursor: pointer;
}

.registration_agreement input {
	min-width: 22px;
	width: 22px;
	height: 22px;
	background: #ffffff;
	cursor: pointer;
}
</style>
